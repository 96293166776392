/* custom-tree.css */

.node__root > circle {
  fill: #dd3fff;
}

.node__branch > circle {
  fill: #dd3fff;
}

.node__leaf > circle {
  fill: #dd3fff;
  /* Let's also make the radius of leaf nodes larger */
  r: 15;
}
.rd3t-link {
  fill: none;
  stroke: #DADADA!important;
  stroke-dasharray: 10,10;
}
.rd3t-label__title {
fill: #ffff!important;
stroke: none;
font-weight: bolder;
}
.t-btn {
border-radius: 5px;
min-height: 30px;
width: auto;
padding: 5px 4px;
margin-bottom: 0px;
min-width: 63px;
/* word-break: break-all; */
text-align: center;

background-image: linear-gradient(90deg, #40caf757 0%,#696efb54 50%, #c438e457 80%);
background-origin: border-box;
box-shadow: inset 0 1000px #151415;
border: 1px solid transparent;
}