.donut-chart-container {
    position: relative;
  }
  
  .apexcharts-donut {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .apexcharts-canvas {
    position: relative;
    user-select: none;
    max-width: 100%;
}
