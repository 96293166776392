.list-btn ul{
    padding-left: 0px;
}
.list-btn ul li {
    list-style: none;
    display: inline-block;
    width: 23%;
    margin-right: 20px;
    margin-bottom: 20px;
}
.list-btn ul li button{
    width: 100%;
}