.bannerBlog {
    position: relative;
}
.cardBlock {
    width: 60%;
    background-color: #000;
    /* margin-top: -192px; */
    position: absolute;
    left: 25px;
    bottom: -26px;
}
.bg-toast {
    background: #e1b02817;
    padding: 1px 10px;
    border-radius: 5px;
}
.bannerBlog .bannerContent {
    height: 600px;
    object-fit: cover;
}
@media only screen and (max-width: 991px) {
    .cardBlock {
        width: 80%;
    }
    
  }
  @media only screen and (max-width: 500px) {
  .bannerBlog .bannerContent {
    height: 400px;
    object-fit: cover;
}


}