.pl-1 {
    padding-left: 1rem;
}
.pl-2 {
    padding-left: 2rem;
}
.pl-3 {
    padding-left: 3rem;
}
.pl-4 {
    padding-left: 4rem;
}

/* ---------------- */
.accordion {
    --bs-body-bg:transparent;

}
.accordion-button:not(.collapsed) {
    --bs-accordion-active-bg:#cfe2ff00;
    --bs-accordion-active-color:#E1B028;
}
.accordion-button:not(.collapsed)::after {
    --bs-accordion-btn-active-icon:url("data:image/svg+xml,%3Csvg stroke='%23E1B028' fill='none' stroke-width='2' viewBox='0 0 24 24' stroke-linecap='round' stroke-linejoin='round' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'%3E%3Cline x1='5' y1='12' x2='19' y2='12'%3E%3C/line%3E%3C/svg%3E");
    
}
.accordion-button:focus {
    --bs-accordion-btn-focus-box-shadow:transparent;
}
.accordion-item {
    border-top: none;
    border-left: none;
    border-right: none;
    --bs-accordion-border-color: #fffdf62b;
}
.accordion-button::after {
    --bs-accordion-btn-icon:url("data:image/svg+xml,%3Csvg stroke='%23fff' fill='none' stroke-width='2' viewBox='0 0 24 24' stroke-linecap='round' stroke-linejoin='round' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'%3E%3Cline x1='12' y1='5' x2='12' y2='19'%3E%3C/line%3E%3Cline x1='5' y1='12' x2='19' y2='12'%3E%3C/line%3E%3C/svg%3E");
}
.accordion-button {
    font-size: 600;
    font-weight: bold;
    --bs-accordion-btn-padding-y: 1.6rem;
}
/* ------------------------ */
.Boxsticky{
    position: sticky;
    top: 83px;
}