.header__center {
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    grid-column-gap: 0px;
    align-items: center;
}
.header__center::before, .header__center::after {
    content: "";
    display: block;
    height: 1px;
    background-color: #fff;
}
/* ---------- */
.v-line{
    position: relative;
    height: 250px;
}
.v-line:after {
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    background-color: #fff;
    width: 1px;
    right: 0;
    top: 0;
}
.Spacecontent{
    display: flex;
    justify-content: center;
    align-items: center;
}
.spaceconnect{
    background:linear-gradient(45deg, #000000cc, #000000a4,#000000d8),url(../../../../public/assets/images/spacebanner.jpg);
    background-size: cover;
    background-repeat: no-repeat;
}
.commingsoon h2 {
    background-color: #00000085;
}