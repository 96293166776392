.about_bg {
    background: url(../../../public/assets/images/about.png);
    background-repeat: no-repeat;
    background-size: cover;
    height: 400px;
    background-position: 50% 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.border-primary {
    --bs-primary-rgb: 238, 186, 43;
}

/* -------------- */
.bigImage {
    max-height: 500px;
}

.bigImage img {
    height: auto;
}

.videoImg {
    margin-left: -90px;
    max-height: 445px;
}

.video-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    right: 50%;
    height: 14%;
    cursor: pointer;
    width: 10%;
    border-radius: 50%;
    background: #fff;
    top: 42%;
}

/* --------------Our team------------ */
.team {
    text-align: center;
    position: relative;
    margin: 0 auto;
}

.team img {
    margin: 0 auto;
}

.team .text-box {
    background-color: #E1B028CC;
    padding: 10px 6px;
    border-radius: 20px;
    position: absolute;
    left: 0;
    right: 0;
    width: 90%;
    margin: 0 auto;
    bottom: 11px;
}

@media only screen and (min-width: 1199px) {
    .team {
        width: 85%;
    }
}

@media only screen and (max-width: 1199px) {
    .team {
        width: 90%;
    }
}

@media only screen and (max-width: 500px) {
    .about_bg {
        height: 240px;
    }
}