body .input-group .innerBtn {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 111;
    top: 0;
}
body .input-group .innerBtn::before {
    content: "";
    position: absolute;
    right: 0;
    width:2px;
    height: 40px;
    bottom: 0;
    z-index: 2;
    top: 0;
}


body .input-group .innerBtn::before {
    content: "";
    position: absolute;
    left: 0;
    width: 1px;
    background: #39393a;
    height: 30px;
    bottom: 4px;
    z-index: 2;
    top: 4px;
}
.dashboard .input-group .form-control {
    border-radius: 0.375rem!important;
    overflow: hidden;
    padding-right: 138px;
    height: 40px;
    color: #DADADA;
    font-size: 15px;
}
.dashboard .input-group .innerBtn {
    border: none;
}