
  .inbox_msg {
    clear: both;
    overflow: hidden;
  }
  .top_spac{ margin: 20px 0 0;}
  
  
  .recent_heading {float: left; width:40%;}
  .srch_bar {
    display: inline-block;
    text-align: right;
    width: 60%;
  }
  .headind_srch{ padding:10px 29px 10px 20px; overflow:hidden; border-bottom:1px solid #c4c4c4;}
  
  .recent_heading h4 {
    color: #E1B028;
    font-size: 21px;
    margin: auto;
  }
  .srch_bar input{ border:1px solid #cdcdcd; border-width:0 0 1px 0; width:80%; padding:2px 0 4px 6px; background:none;}
  .srch_bar .input-group-addon button {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border: medium none;
    padding: 0;
    color: #707070;
    font-size: 18px;
  }
  .srch_bar .input-group-addon { margin: 0 0 0 -27px;}
  
  .chat_ib h5{ font-size:15px; color:#464646; margin:0 0 8px 0;}
  .chat_ib h5 span{ font-size:13px; float:right;}
  .chat_ib p{ font-size:14px; color:#989898; margin:auto}
  .chat_img {
    float: left;
    width: 11%;
  }
  .chat_ib {
    float: left;
    padding: 0 0 0 15px;
    width: 88%;
  }
  
  .chat_people{ overflow:hidden; clear:both;}
  .chat_list {
    border-bottom: 1px solid #c4c4c4;
    margin: 0;
    padding: 18px 16px 10px;
  }
  .inbox_chat { height: 550px; overflow-y: scroll;}
  
  .active_chat{ background:#ebebeb;}
  
  .incoming_msg_img {
    display: inline-block;
    width: 7%;
  }
  .received_msg {
    display: inline-block;
    padding: 0 0 0 10px;
    vertical-align: middle;
    width: 93%;
   }
   .received_withd_msg p {
    background: #ebebeb none repeat scroll 0 0;
    border-radius: 8px;
    color: #646464;
    font-size: 14px;
    margin: 0;
    padding: 10px 10px 10px 12px;
    width: 100%;
  }
  .time_date {
    color: #747474;
    /* display: none; */
    font-size: 12px;
    margin: 8px 0 0;
  }
  .received_withd_msg { width: 57%;}
  .mesgs {
    float: left;
    padding: 30px 15px 0 25px;
    width: 100%;
  }
  
   .sent_msg p {
    background: #E1B028 none repeat scroll 0 0;
    border-radius: 3px;
    font-size: 14px;
    margin: 0; color:#000;
    padding: 10px 10px 10px 12px;
    width:100%;
  }
  .outgoing_msg{ overflow:hidden; margin:26px 0 26px;}
  .sent_msg {
    float: right;
    width: 46%;
  }
  .input_msg_write input {
    background: #fff none repeat scroll 0 0;
    border: medium none;
    color: #000;
    outline: none;
    border-radius: 8px;
    font-size: 15px;
    padding: 10px 10px 10px 12px;
    width: 100%;
  }
  .input_msg_write select {
    background: #fff none repeat scroll 0 0;
    border: medium none;
    color: #000;
    outline: none;
    appearance: none;
    border-radius: 8px;
    font-size: 15px;
    padding: 10px 10px 10px 12px;
    width: 100%;
}
.input_msg_write select {
  overflow: hidden;
  overflow: -moz-hidden-unscrollable;
  background: url(../../../../public/assets/images/down.png) no-repeat right white;
  display: inline-block;
  position: relative;
  background-position: right 16px center;
  display: inline-block;
    background-size: 20px;
}

/* .input_msg_write select {
  background: transparent;
  -webkit-appearance: none;
  width: 100px;
  font-size: 11px;
  border: 0;
  height: 17px;
  position: absolute;
  left: 0;
  top: 0;
} */
  
  .type_msg {position: relative;}
  .msg_send_btn {
    background: #E1B028 none repeat scroll 0 0;
    border: medium none;
    border-radius: 8px;
    color: #fff;
    cursor: pointer;
    font-size: 17px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0px;
    width: 140px;
    padding: 10px 10px 10px 12px;
}
  .messaging { padding: 0 0 25px 0;}
  .msg_history {
    height: 470px;
    overflow-y: auto;
  }

  /* ---------chat accordion css------------ */
  .chat-accordion .accordion-button:not(.collapsed) {
    --bs-accordion-active-bg: rgba(225, 176, 40, 0.10);
    --bs-accordion-active-color: #fff;
    border-radius: 15px 15px 0px 0px;
}
.chat-accordion .accordion-button{
    --bs-accordion-btn-padding-y: 0.80rem;
}

.uni_num{
  font-size: 11px;
  word-wrap: normal;
  display: block;
}

.cht_img{
    height: 40px;
    width: auto;
    object-fit: contain;
}

@media only screen and (max-width: 500px) {
  .mesgs {
    padding: 30px 0px 0 0px;
  }
  .msg_history {
    margin-bottom: 20px;
}
.msg_send_btn {
  width: 75px;
}
}
