header nav {
    background: rgba(255, 255, 255, 0.10);
    box-shadow: 0px 2px 100px 0px rgba(255, 255, 255, 0.10);
    backdrop-filter: blur(50px);
}

.profile-icon img {
    height: 40px;
    width: 35;
    object-fit: contain;
    width: 40px;
    padding: 6px;
    border-radius: 50px;
    border: 1px solid #39393a;
}

.btn-header button {
    width: auto;
}

.nav-link {
    --bs-nav-link-color: #fff;
}

.nav-link:focus,
.nav-link:hover {
    --bs-nav-link-hover-color: #fff;
}

.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
    --bs-navbar-active-color: #fff;
}

.dropdown-item:focus,
.dropdown-item:hover {
    --bs-dropdown-link-hover-color: #000;
}

.nav-link:focus-visible {
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 0%);
}

.navbar-brand {
    --bs-navbar-brand-color: #fff;
}

.navbar-brand:focus,
.navbar-brand:hover {
    --bs-navbar-brand-hover-color: #fff;
}

.navbar-toggler {
    --bs-navbar-toggler-border-color: #E1B028;
}

.navbar-toggler-icon {
    --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(255, 255, 255)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

body .btn-close {
    --bs-btn-close-bg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3E%3Cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E");
}


@media (min-width: 1200px) {

    .navbar-expand-xl .navbar-nav .nav-link {
        margin-right: 1rem;
    }
}

@media (max-width: 1199px) {
    .offcanvas-body form button {
        margin-top: 1rem;
    }

    .btn-header {
        padding-top: 0.5rem;
    }
}

@media (max-width: 991px) {
    .offcanvas-body form button {
        margin-top: 1rem;
    }

}