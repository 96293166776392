.goog-te-gadget {
    color: transparent !important;
}
.goog-te-gadget .VIpgJd-ZVi9od-l4eHX-hSRGPd {
    display: none;
}

.goog-te-gadget .goog-te-combo {
    margin: 0px 0!important;
    height: 40px;
    border: 1px solid #333437;
    background: #0c0c0c;
    padding: 0px 9px;
    border-radius: 7px;
    color: #fff;
    outline: none;
    cursor: pointer;
}
#google_translate_element {
    max-height: 40px;
}
.goog-te-gadget {
    font-size: 0px!important;
}