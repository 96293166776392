.css-1im77uy-control {
    background-color: transparent;
    border-color: #39393a;
    border-radius: 0.375rem;

}
.css-163tuk4-control {

    background-color: transparent;
    border-color: #39393a;

}
.css-edv7mi-control {

    background-color: transparent;
}
.css-1jdw2ov-menu{
    background-color: #000;
}

.dashboard .form-select {
    --bs-body-bg:#0e0d0b;
    --bs-border-color: #39393a;
}

.dashboard .form-select:focus {
    border-color: #39393a;
    outline: 0;
    box-shadow: none;
}
.uploadImage {
    border-radius: 24px;
    border: 1px solid rgba(218, 218, 218, 0.20);
    background: #1B191580;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.uploadImages {
    --bs-body-color: #39393a;
}