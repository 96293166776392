.bg-light-grey {
    background: #dadada0d;
}

/* ------------------- */
.banner {
    background: url(../../../public/assets/images/globe.png);
    min-height: 640px;
    background-size: 100% 100%;
    margin-top: 65px;
}

.banner-content .m-head {
    font-size: 60px;
}

.banner-content h2 {
    text-shadow: 0px 0px 50px #FFF;
}

/* ------------------ */
.empowering {
    background: url(../../../public/assets/images/empower_bg.png);
    background-size: 100%;
}

.wave_line {
    position: absolute;
    z-index: -1;
}

/* --------------- */
.shape {
    position: absolute;
}

.left-shape {
    left: 0;
    top: 0;
    width: 220px;
}

/* ------------ */
.right-shape {
    right: 0;
    bottom: 0;
    width: 220px;
    opacity: 1;
    z-index: -1;
}

.line-circle-shape {
    width: 54%;
    right: 0;
    bottom: -35%;
    z-index: -1;
}

.right-ellipse-shape {
    right: 0;
    bottom: 0;
    width: 220px;
    opacity: 1;
    z-index: -1;
}

/* ----------------------- */
.feature-list {
    margin-bottom: .5rem;
}

.box {
    border-radius: 32px;
    border: 1px solid rgba(238, 186, 43, 0.20);
    background: rgba(115, 115, 115, 0.10);
    padding: 15px 15px 15px 15px;
    min-height: 185px;
}

.box img {
    width: 80px;
    height: 100px;
}

/* -----Currency list------- */
.currency-list ul {
    padding-left: 0px;
}

.currency-list li {
    padding: 0px 20px 0px 0px;
    margin-bottom: 10px;
}

.currency-list li:last-child {
    padding-right: 0px !important;
}

.currency-list img {
    width: 90px;
}

/* ---------partner logo----------- */
.partner-logo {
    background: #fff;
    padding: 14px;
    text-align: center;
    border-radius: 12px;
}

.partner-logo img {
    width: 190px;
    height: 70px;
    object-fit: contain;
}

/* ---------------- */
.bg-galaxy {
    background: url(../../../public/assets/images/bg_galaxy.png);
    background-size: cover;
    background-repeat: no-repeat;
}

.water_circular img {
    height: 400px;
    width: 445px;
    object-fit: contain;
}

.infinity img {
    height: 320px;
    width: 100%;
    object-fit: contain;
}

/* ----------- */
.it_head {
    position: relative;
    top: 25px;
}

/* --------Modal start---------- */
.modal-content {
    --bs-modal-border-color: rgba(218, 218, 218, 0.20);
    --bs-modal-bg: #010101ee;
}

.btn-close {
    --bs-btn-close-focus-shadow: 0 0 0 0.25rem rgb(13 110 253 / 0%);
}

.modal-p-logo ul li {
    display: inline-block;
    margin-right: 25px;
}

.modal-p-logo ul {
    padding-left: 0px;
    list-style: none;
    margin-bottom: 0px;
}

/* ------------ */

@media (max-width: 1399px) {
    .box {
        padding: 14px 9px;
        min-height: 184px;
    }
}

@media (max-width: 991px) {
    .banner-content h1 {
        font-size: 43px;
    }
}

@media (max-width: 768px) {
    .currency-list img {
        width: 45px;
    }
}

@media (max-width: 767px) {
    .text-sm-center {
        text-align: center;
    }

    .it_head {
        top: 0px;
    }

    .banner-content .m-head {
        font-size: 30px;
    }
}

@media (max-width: 425px) {
    .water_circular img {
        height: 400px;
        width: 100%;
    }

    .currency-list li:last-child {
        padding-right: 15px !important;
    }
}

@media (max-width: 340px) {
    .currency-list li {
        padding: 0px 16px 0px 0px;
        margin-bottom: 10px;
    }
}

@media (max-width: 320px) {
    .modal-p-logo ul li {
        margin-right: 10px;
    }

    .currency-list li {
        padding: 0px 10px 0px 0px;
        margin-bottom: 10px;
    }

    .box img {
        width: 59px;
        height: 80px;
    }

    .box {
        padding: 15px 9px;
        min-height: 174px;
    }

}