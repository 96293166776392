body {
  margin: 0;
  --bs-body-font-family: Saira;
  --bs-body-bg: #010000;
  --bs-body-color: #fff;
  --bs-body-font-weight: 200;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* vietnamese */
@font-face {
  font-family: 'Saira';
  font-style: italic;
  font-weight: 100 900;
  font-stretch: 100%;
  font-display: swap;
  src: url(../public/assets/fonts/memhYa2wxmKQyNkiV50dulWP7s95AqZTzbvffS2-vw.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Saira';
  font-style: italic;
  font-weight: 100 900;
  font-stretch: 100%;
  font-display: swap;
  src: url(../public/assets/fonts/memhYa2wxmKQyNkiV50dulWP7s95AqZTzbvefS2-vw.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Saira';
  font-style: italic;
  font-weight: 100 900;
  font-stretch: 100%;
  font-display: swap;
  src: url(../public/assets/fonts/memhYa2wxmKQyNkiV50dulWP7s95AqZTzbvQfS0.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* vietnamese */
@font-face {
  font-family: 'Saira';
  font-style: normal;
  font-weight: 100 900;
  font-stretch: 100%;
  font-display: swap;
  src: url(../public/assets/fonts/memjYa2wxmKQyPMrZX79wwYZQMhsyuSLh4vSZSk.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Saira';
  font-style: normal;
  font-weight: 100 900;
  font-stretch: 100%;
  font-display: swap;
  src: url(../public/assets/fonts/memjYa2wxmKQyPMrZX79wwYZQMhsyuSLhovSZSk.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Saira';
  font-style: normal;
  font-weight: 100 900;
  font-stretch: 100%;
  font-display: swap;
  src: url(../public/assets/fonts/memjYa2wxmKQyPMrZX79wwYZQMhsyuSLiIvS.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* ----------------------- */
.text-right {
  text-align: right;
}

.text-primary {
  --bs-primary-rgb: 225, 176, 40;
}

img,
svg {
  max-width: 100%;
}

body .btn-primary {
  --bs-btn-color: #1B1915;
  --bs-btn-bg: #E1B028;
  --bs-btn-border-color: #E1B028;
  --bs-btn-hover-color: #1B1915;
  --bs-btn-hover-bg: #d8a310;
  --bs-btn-hover-border-color: #c29109;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #1B1915;
  --bs-btn-active-bg: #c29109;
  --bs-btn-active-border-color: #c29109;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #1B1915;
  --bs-btn-disabled-bg: #E1B028;
  --bs-btn-disabled-border-color: #E1B028;
}

body .btn {
  --bs-btn-font-weight: 500;
}

::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

/* ----------- */
.v-Modal {
  z-index: 11111;
}

.modal-backdrop {
  --bs-backdrop-bg: #00000017;
}