.text-decoration-none{
    text-decoration: none!important;
}
.logoMobile{
    display: none;
}
.text-white{
    color: #fff;
}
.dashboard .sidebar a {
    color: #fff;
}
.navbarlist {
    margin-top: 30px;
}
.notification-dropdown .dropdown-menu {
    --bs-dropdown-min-width: 22rem;
    --bs-dropdown-padding-y: 0px;
}
.notification-dropdown .list-group {
    --bs-body-color:#000;
    --bs-list-group-bg: #ffffff;
}
.notification-title {
    padding: 0.75rem 1rem;
}
.navbarlist svg {
    color: #e1b028;
    fill: #e1b028;
}
.dashboard .dropdown-item {
    --bs-dropdown-link-color:#000;
    --bs-dropdown-item-padding-y: 0.50rem;
}
.dashboard .dropdown-menu{
    --bs-dropdown-padding-y: 0px;
    overflow: hidden;
}
.dashboard .dropdown-item:focus, .dashboard .dropdown-item:hover {
    background: #0e0d0b!important;
    --bs-dropdown-link-hover-color: #fff;
}
@media (min-width: 600px) {
body .css-17ln3x6 {
    min-height: 68px;
}}





@media only screen and (max-width: 576px) {
 .headerSearch .searchfield{
    display: none!important;
 }
 .logoMobile{
    display: block;
}
 .sidebar {
    position: absolute;
    transition:cubic-bezier(0.4, 0, 0.6, 1) 5ms;
}
.sidebar .navbarlist {
    margin-top: 100px!important;
    background-color: #010000!important;
}
.css-1m8hle5-MuiDrawer-docked .MuiDrawer-paper {
    background-color: transparent!important;
}
.mainBox {
    margin-top: 130px!important;
}
  }
  @media only screen and (max-width: 425px) {
  .notification-dropdown .dropdown-menu {
    --bs-dropdown-min-width: 16rem;
    --bs-dropdown-padding-y: 0px;
}}