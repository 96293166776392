:not(.btn-check)+.btn:active {
    --bs-btn-active-bg:#E1B028;
    --bs-btn-active-border-color:#E1B028;
    --bs-btn-active-color:#fff;
}
/* /---------- */
.browseBtn{
    position: relative;
}
.browseBtn input {
    position: absolute;
    top: 0;
    opacity: 0;
    width: 82px;
    border-radius: 21px;
}
/* ------------ */
.Downloadbtn svg {
    font-size: 20px;
}
.Downloadbtn:hover svg {
    fill: #fff;
}
