.hr-line{
    stroke-width: 1px;
stroke:  #DADADA;
opacity: 0.1;
}
.globe_earth{
    background: url(../../../public/assets/images/globe_earth.png);
    background-size: 100% 100%;
    background-position: bottom;
}
.footer-block {
    margin-top: 30px;
}
.footer-block ul {
    list-style: none;
    padding-left: 0px;
}
.footer-block ul li {
    margin-bottom: 10px;
}
.footer-block ul li a{
    color: #DADADA;
}
/* ------------------ */
.social-icon ul{
    padding-left: 0px;
    list-style: none;
}
.social-icon ul li{
    display: inline-block;
    margin-right: 10px;
    width: 45px;
    height: 45px;
    background-color: #1B1915;
    border-radius: 50%;
}
.social-icon ul li svg {
    width: 45px;
    height: 45px;
    padding: 10px;
    object-fit: contain;
    fill: #E1B028;
}
.social-icon ul li:last-child{
    margin-right: 0px;
}
