body {
    background-color: #010000 !important;
    font-family: 'Saira' !important;
}

body .css-i4bv87-MuiSvgIcon-root {
    fill: #E1B028;
}

body .css-19z1ozs-MuiPaper-root-MuiAppBar-root {
    background-color: #0c0c0c;
}

body .css-168noda {
    background-color: #0c0c0c;
}

body .css-yb0lig {
    font-family: inherit;
}

body .css-17ln3x6 {
    justify-content: center;
}

body .css-10hburv-MuiTypography-root {
    font-family: 'Saira';
}

.border-right {
    border-right: 1px solid #dee2e6 !important;
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

/* ------------ */
.text-blue {
    color: #2888E1;
}

.pr-1 {
    padding-right: 1rem;
}

.pr-2 {
    padding-right: 2rem;
}

.pr-3 {
    padding-right: 3rem;
}

.pr-4 {
    padding-right: 4rem;
}

.pl-1 {
    padding-left: 1rem;
}

.pl-2 {
    padding-left: 2rem;
}

.pl-3 {
    padding-left: 3rem;
}

.pl-4 {
    padding-left: 4rem;
}

.text-white {
    --bs-white-rgb: 255, 253, 246;
}

/* -------------header css---------------- */
.header-nav ul {
    list-style: none;
    padding-left: 0px;
}

.header-nav ul li {
    display: inline-block;
    margin-right: 10px;
    padding: 8px;
    vertical-align: middle;
}

.header-nav ul li:last-child {
    margin-right: 0px;
    padding-right: 0px;
}

.bg-danger-gradient {
    background-color: #e55353;
    background-color: var(--cui-danger, #d93737);
    background-image: linear-gradient(45deg, #e55353, #d93737);
    background-image: linear-gradient(45deg, var(--cui-danger-start, #e55353) 0, var(--cui-danger-stop, #d93737 100%));
}

.header-nav ul li svg {
    font-size: 22px;
}

.notification .badge {
    width: 12px;
    height: 12px;
    font-size: 8px;
    margin-top: -1px;
    padding: 3px;
}

.header-nav .dropdown-toggle::after {
    display: none;
}

.headerSearch .searchfield .search-icon {
    position: absolute;
    top: 5px;
    bottom: 0;
    left: 8px;
}

.headerSearch .searchfield .form-control {
    padding-left: 34px;
}

/* ------------sidebar--------------- */
.sidebar .MuiDrawer-paperAnchorLeft {
    border: none !important;
    overflow: hidden !important;
}

.sidebar .navbarlist {
    margin-top: 35px;
}

.navbarlist {
    border-radius: 0px 24px 24px 0px;
    width: 97%;
    height: 496px;
    overflow-y: auto;
    border-radius: 0px, 24px, 24px, 0px;
    background: url(../../../../public/assets/images/dashboard/bg_shape.png);
    background-size: cover;
    background-repeat: no-repeat;
    overflow-x: hidden;
    box-shadow: 0px 0px 8px 0px rgba(255, 255, 255, 0.20);
}

/* -------------form control--------------------- */
.dashboard .form-control {
    --bs-border-color: #39393a;
    --bs-body-bg: #0c0c0c;
}

.dashboard .form-control:focus {
    border-color: var(--bs-border-color);
    box-shadow: 0 0 0 .25rem transparent;
}

/* -------------------- */
.Tablist {
    border-radius: 16px;
    border: 1px solid rgba(218, 218, 218, 0.20);
    background: rgba(27, 25, 21, 0.50);
    padding: 15px 20px;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.Tablist .t-list {}

.Tablist ul li {
    display: inline-block;
    margin-right: 20px;
    text-align: center;
}

/* -------------------- */
.cardBox {
    border-radius: 16px;
    border: 1px solid rgba(218, 218, 218, 0.20);
    background: rgba(27, 25, 21, 0.50);
}

.card-list {
    color: #DADADA;
}

.card-list ul {
    padding-left: 0px;
}

.boxlist button {
    margin-bottom: 8px;
}

.card-list .btn-grey {
    padding: 8px 0px;
}

.card-list ul li {
    display: inline-block;
    min-width: 18%;
    margin-right: 20px;
    text-align: center;
}

.card-list .active {
    border-radius: 5px;
    border: 1px solid rgba(225, 176, 40, 0.50);
    background: rgba(225, 176, 40, 0.10);
}

.card-list .nonactive {
    border-radius: 5px;
    background: rgba(255, 253, 246, 0.10);
}

.card-list ul li:last-child {
    margin-right: 0px;
}

/* --------------------- */
.iconBox {
    width: 38px;
    height: 38px;
    display: flex;
    padding: 4px;
    justify-content: center;
    border-radius: 8px;
    align-items: center;
}

.cardBox p {
    color: #DADADA;
}

.chart-wave-line {
    margin-bottom: -14px;
    margin-top: -14px;
}

/* -----dashboard table--------- */
.d-table table th,
.d-table table td {
    color: #fff;
}

.d-table table thead th {
    background-color: #241f11;
    font-weight: 400;
    text-transform: uppercase;
}

.d-table table td {
    font-weight: 300;
    padding: 15px 10px;
    vertical-align: middle;
}

.d-table .table {
    --bs-table-bg: transparent;
}

.d-table .table>:not(caption)>*>* {
    --bs-table-bg: transparent;
    border-color: #232323;
}

body .dashboard .btn-outline-primary {
    --bs-btn-bg: rgba(225, 176, 40, 0.10);
    --bs-btn-border-color: rgba(225, 176, 40, 0.50);
    --bs-btn-color: #DADADA;
}

body .dashboard .btn:hover {
    --bs-btn-hover-bg: #E1B028;
    --bs-btn-hover-border-color: #E1B028;
}

body .dashboard .btn:first-child:active {
    --bs-btn-active-bg: #E1B028;
    --bs-btn-active-border-color: #E1B028;
}

/* body .dashboard .btn-primary{
    --bs-btn-bg: rgba(225, 176, 40, 0.10);
    --bs-btn-border-color: rgba(225, 176, 40, 0.50);
    --bs-btn-color: #DADADA;
} */

body .dashboard .d-table table tr:last-child td {
    border-color: transparent;
}

.sameHeight {
    min-height: 388px;
}

/* ------------------- */
/* width */
::-webkit-scrollbar {
    width: 5px;
    height: 5px;

}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #232323;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #232323;
}

/* -------------------------- */
.dashboard input::placeholder {
    color: #39393a;
    opacity: 1;
    /* Firefox */
}

.dashboard input::-ms-input-placeholder {
    /* Edge 12-18 */
    color: #39393a;
}

/* ---------Modal start css------------ */
.d-modal.modal {
    --bs-modal-zindex: 1201;
    --bs-body-bg: #fff;
    border-radius: 32px;
}

.d-modal .modal-header {
    background-color: #1B1915;
    color: #fff;
    border-radius: 10px 10px 0px 0px;
}

.d-modal .modal-footer {
    --bs-modal-footer-bg: #1B1915;
    color: #fff;
    border-radius: 0px 0px 10px 10px;
}

.d-modal .modal-content {
    border-radius: 15px;
    box-shadow: 0px 0px 8px 0px rgba(255, 255, 255, 0.36);
    overflow: hidden;
}

.d-modal .modal-header .btn-close {
    border-radius: 100px;
    /* box-shadow: 0px 0px 6px 0px rgba(255, 255, 255, 0.25); */
}

/* -------------- */
.piechart canvas {
    width: 100%;
}

.donut-chart-container #chart {
    display: flex;
    justify-content: center;
}

/* -------- */
.pagination {
    --bs-link-color: #fff;
}

.active>.page-link,
.page-link.active {
    --bs-pagination-active-color: #000;
    --bs-pagination-active-bg: #E1B028;
    --bs-pagination-active-border-color: #E1B028;

}

.page-link:hover {
    --bs-pagination-hover-color: #000;
    --bs-pagination-hover-bg: #FFFDF6;
    --bs-pagination-hover-border-color: #FFFDF6;
}

.page-link:focus {
    --bs-pagination-focus-color: #000;
    --bs-pagination-focus-box-shadow: none;
}

.page-link {
    --bs-pagination-border-color: rgba(218, 218, 218, 0.20);
    border-radius: 0px;
}

/* -------- */
@media only screen and (max-width: 600px) {
    .d-table {
        display: inherit !important;
    }
}

@media only screen and (max-width: 425px) {
    .card-list ul {
        margin-bottom: 0px;
    }

    .card-list ul li {
        display: inline;
        min-width: 100%;
        margin-right: 20px;
        text-align: center;
    }

    .boxlist button {
        margin-bottom: 8px;
        width: 100%;
    }

}

@media only screen and (max-width: 375px) {
    .header-nav ul li {
        padding: 8px 0px 8px 0px;
    }
}

@media only screen and (max-width: 320px) {
    .header-nav ul li {
        margin-right: 2px;
    }
}

.cardBox.membercard {
    height: 200px;
}